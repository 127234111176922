import { createApp, defineAsyncComponent } from 'vue';
import { createPinia } from 'pinia';
import '@/js/bootstrap';
import '@/js/helpers/themeSwitcherClick';
import '@/js/helpers/setDynamicVH';

const GlobalSearch = defineAsyncComponent(() => import('@/js/global/menu/GlobalSearch.vue'));
const FiatCurrencySearch = defineAsyncComponent(() => import('@/js/global/top-menu/FiatCurrencySearch.vue'));
const CurrencyLanguageSearch = defineAsyncComponent(() => import('@/js/global/top-menu/CurrencyLanguageSearch.vue'));
const GasPriceTooltip = defineAsyncComponent(() => import('@/js/global/top-menu/GasPriceTooltip.vue'));
const Translate = defineAsyncComponent(() => import('@/js/helpers/Translate.vue'));
const CompanionBubble = defineAsyncComponent(() => import('@/js/global/top-menu/CompanionBubble.vue'));
const MarketingCampaignComponents = defineAsyncComponent(() => import('@/js/marketing-campaigns/MarketingCampaignComponents.vue'));
const MobileMenuToggleButton = defineAsyncComponent(() => import('@/js/global/buttons/MobileMenuToggleButton.vue'));
const PromoNotifications = defineAsyncComponent(() => import('@/js/global/top-menu/PromoNotifications.vue'));
const StoreMenuData = defineAsyncComponent(() => import('@/js/global/menu/partials/StoreMenuData.vue'));
const MainMenu = defineAsyncComponent(() => import('@/js/global/menu/MainMenu.vue'));
const SubscribeNewsletterFooterForm = defineAsyncComponent(() => import('@/js/subscribe/SubscribeNewsletterFooterForm.vue'));
const SubscribeNewsletterArticle = defineAsyncComponent(() => import('@/js/subscribe/SubscribeNewsletterArticle.vue'));
const BaseTippy = defineAsyncComponent(() => import('@/js/components/Tippy/BaseTippy.vue'));
const NewsletterInput = defineAsyncComponent(() => import('@/js/newsletter/NewsletterInput.vue'));

const app = createApp({});

app.use(createPinia());

// Helpers
app.component('Translate', Translate);
app.config.globalProperties.Translate = Translate.methods;

// Global
app.component('StoreMenuData', StoreMenuData);
app.component('MainMenu', MainMenu);
app.component('MobileMenuToggleButton', MobileMenuToggleButton);
app.component('GlobalSearch', GlobalSearch);
app.component('FiatCurrencySearch', FiatCurrencySearch);
app.component('CurrencyLanguageSearch', CurrencyLanguageSearch);
app.component('PromoNotifications', PromoNotifications);
app.component('GasPriceTooltip', GasPriceTooltip);
app.component('CompanionBubble', CompanionBubble);
app.component('MarketingCampaignComponents', MarketingCampaignComponents);
app.component('SubscribeNewsletterFooterForm', SubscribeNewsletterFooterForm);
app.component('SubscribeNewsletterArticle', SubscribeNewsletterArticle);
app.component('BaseTippy', BaseTippy);
app.component('NewsletterInput', NewsletterInput);

const mountApp = () => {
    app.mount('#app-mount');
};

export { mountApp, app };
